import { createStore } from "vuex";
import createPersistedate from "vuex-persistedstate";

export default createStore({
  state: {
    // 用户中心表头总页数
    totalNum: "",
    // 登录状态相关-----------------------------
    token: "", // 登录token
    usertInfo: "", // 用户基础信息
    userName: "", // 用户名
    headSculpture: "", // 用户头像
    // totalNum: '',
    avatarUp: "", // 用户上传头像暂存
    haveCards: "", // 用户持有电子卡 1个人 2机构 3办公室

    calendarShow: false, // 日历弹窗显示
  },
  getters: {},
  mutations: {
    total(state, payload) {
      state.totalNum = payload;
    },
    // 登录状态相关-----------------------------
    // 清除登录
    lodingClean(state, payload) {
      state.token = payload.token;
      state.usertInfo = payload.usertInfo;
      state.userName = payload.userName;
      state.headSculpture = payload.headSculpture;
      state.avatarUp = payload.avatarUp;
      state.haveCards = payload.haveCards;
    },
    // 保存登录信息
    lodingSave(state, payload) {
      state.token = payload.token;
      state.usertInfo = payload.usertInfo;
      state.userName = payload.userName;
      state.headSculpture = payload.headSculpture;
    },
    // 保存用户上传头像
    avatarUpSave(state, payload) {
      state.avatarUp = payload;
    },
    // 保存用户持有电子卡
    haveCardsSave(state, payload) {
      state.haveCards = payload;
    },
    // 日历弹窗显示状态更新
    calendarShowChange(state, payload) {
      state.calendarShow = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedate({
      key: "totalNum",
    }),
  ],
});
